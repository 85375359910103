body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.formWrapper {
    margin: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border: 1px solid silver;
    border-radius: 4px;
}

.formWrapper button {
    width: 100%;
}

.formWrapper h1,
.formWrapper p {
    padding-left: 12.5rem;
}

.formWrapper .message {
    background: orange;
    padding: 1rem;
    padding-left: 12.5rem;
}

.formWrapper .qrCode {
    padding: 1rem;
    margin-left: -1rem;
    width: 100%;
    text-align: center;
}
